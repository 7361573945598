import * as React from 'react'
import { useTranslation } from 'react-i18next'
import BackgroundLogo from '@assets/icons/backgroundLogo.svg'
import { useScrollToLocationHash } from '@hooks/useScrollToLocationHash'
import { DealerContacts } from '@layout/DealerContacts'
import { Page, PageContent } from '@layout/Page'
import { PageSection } from '@layout/PageSection'
import pageSectionStyles from '@layout/PageSection/PageSection.module.css'
import { DiscountVehicles } from '@layout/DiscountVehicles'
import { ContactForm } from '@forms/ContactForm'
import { TopMain } from './TopMain'
import { ChooseUs } from './ChooseUs'
import { Testimonials } from './Testimonials'
import styles from './MainPage.module.css'
import { OurClients } from './OurClients'

export const MainPage: React.FC = () => {
  const { t } = useTranslation()
  const pageId = React.useId()

  useScrollToLocationHash()

  return (
    <Page id={pageId}>
      <PageContent className={styles.mainPageContainer}>
        <img src={BackgroundLogo} alt="logo" className={styles.backgroundLogo} />
        <TopMain />
        <Testimonials />
        <PageSection title={t('pages.main.sections.deals.title')} maxWidth={false} disableRightGap disableLeftGap>
          <DiscountVehicles buttonsProps={{ className: pageSectionStyles.ignoreExceptions }} />
        </PageSection>
        <PageSection className={styles.contentGap}>
          <ContactForm topic="other" />
          <DealerContacts />
        </PageSection>
        <PageSection id="miks_carprof">
          <ChooseUs />
        </PageSection>
        <PageSection>
          <OurClients />
        </PageSection>
      </PageContent>
    </Page>
  )
}
